import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 店铺
export function updateShop(data) {
  return request({
    url: '/store/update',
    method: 'post',
    data: param2Obj(data)
  })
}

export function setDomain(data) {
  return request({
    url: '/store/setDomain',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMaterial(data) {
  return request({
    url: '/seller/store/getmaterial',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateMaterial(data) {
  return request({
    url: '/seller/store/setmaterial',
    method: 'post',
    data: param2Obj(data)
  })
}


export function getMobileDecorate(data) {
  return request({
    url: '/seller/diy/mobile/page/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createMobileDecorate(data) {
  return request({
    url: '/seller/diy/mobile/page/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateMobileDecorate(data) {
  return request({
    url: '/seller/diy/mobile/page/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function onlineMobileDecorate(data) {
  return request({
    url: '/seller/diy/mobile/page/online',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteMobileDecorate(data) {
  return request({
    url: '/seller/diy/mobile/page/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

