<template>
	<div class="material-upload">
		<el-tabs v-model="subactiveName">
			<!-- <el-tab-pane :label="$t('seller.material.shopPane1')" name="1-1"></el-tab-pane> -->
			<el-tab-pane :label="$t('seller.material.shopPane2')" name="1-2">
				<div class="material-wrap">
					<div class="page-warn">
						<i class="el-icon-warning-outline"></i><span>{{$t('seller.material.shopTips1')}}</span>
					</div>
					<div class="page-steps">
						<img src="@/assets/images/seller/tmc-steps-1.png" class="item-icon" alt="">
						<span class="item-text">{{$t('seller.material.shopSteps2-1')}}</span>
						<img src="@/assets/images/seller/tmc-steps-2.png" class="item-icon" alt="">
						<span class="item-text">{{$t('seller.material.shopSteps2-2')}}</span>
						<img src="@/assets/images/seller/tmc-steps-line.png" class="item-line" alt="">
						<img src="@/assets/images/seller/tmc-steps-3.png" class="item-icon" alt="">
						<span class="item-text">{{$t('seller.material.shopSteps2-3')}}</span>
						<img src="@/assets/images/seller/tmc-steps-line.png" class="item-line" alt="">
						<img src="@/assets/images/seller/tmc-steps-4.png" class="item-icon" alt="">
						<span class="item-text">{{$t('seller.material.shopSteps2-4')}}</span>
					</div>
					<div class="page-table">
						<table cellpadding="0" cellspacing="0">
							<thead>
								<tr>
									<th width="210px">{{$t('seller.tableHead.introduce')}}<i class="el-icon-warning-outline" @click="handleExplainShow(1)"></i></th>
									<th width="120px">{{$t('seller.tableHead.shopWideGraph')}}<i class="el-icon-warning-outline" @click="handleExplainShow(2)"></i></th>
									<th width="120px">{{$t('seller.tableHead.shopLongGraph')}}<i class="el-icon-warning-outline" @click="handleExplainShow(3)"></i></th>
									<th>{{$t('seller.tableHead.shopEffect')}}<i class="el-icon-warning-outline" @click="handleExplainShow(4)"></i></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<el-input v-model="materialInfo.intro" maxlength="24" show-word-limit :placeholder="$t('seller.placeholder.shopIntro')">
										</el-input>
										<div class="table-error ml24">{{errorText.intro?errorText.intro:''}}</div>
										<div class="desc-action">
											<el-button @click="handleDescSubmit" size="mini">{{$t('seller.actions.submit')}}</el-button>
										</div>
									</td>
									<td align="center">
										<div class="fake-upload">
											<div class="fake-upload-main">
												<template v-if="materialInfo.wide_graph">
													<img class="fake-upload-pic" :src="materialInfo.wide_graph" alt="">
												</template>
												<template v-else>
													<i class="el-icon-plus"></i>
													<div class="fake-upload-text">{{$t('seller.actions.addImage')}}</div>
													<div class="fake-upload-size">900 X 506px</div>
												</template>
											</div>
											<div class="fake-upload-bottom">
												<el-upload
													class="fake-upload-btn btn-primary"
													action="#"
													:show-file-list="false"
													v-loading="loadingUplaodW"
													:http-request="httpRequestMaterialW">
													<i class="el-icon-upload2"></i>
												</el-upload>
												<div class="fake-upload-btn btn-blue" @click="handleUploadShow('wide')"><i class="el-icon-setting"></i></div>
											</div>
										</div>
										<div class="table-error">{{errorText.wide_graph?errorText.wide_graph:''}}</div>
									</td>
									<td align="center">
										<div class="fake-upload">
											<div class="fake-upload-main">
												<template v-if="materialInfo.long_graph">
													<img class="fake-upload-pic" :src="materialInfo.long_graph" alt="">
												</template>
												<template v-else>
													<i class="el-icon-plus"></i>
													<div class="fake-upload-text">{{$t('seller.actions.addImage')}}</div>
													<div class="fake-upload-size">900 X 1600px</div>
												</template>
											</div>
											<div class="fake-upload-bottom">
												<el-upload
													class="fake-upload-btn btn-primary"
													action="#"
													:show-file-list="false"
													v-loading="loadingUplaodL"
													:http-request="httpRequestMaterialL">
													<i class="el-icon-upload2"></i>
												</el-upload>
												<div class="fake-upload-btn btn-blue" @click="handleUploadShow('long')"><i class="el-icon-setting"></i></div>
											</div>
										</div>
										<div class="table-error">{{errorText.long_graph?errorText.long_graph:''}}</div>
									</td>
									<td>
										<div class="table-effect">
											<div class="effect-list">
												<div class="item">
													<div class="item-label">{{$t('seller.material.shopEffect')}}1</div>
													<div class="item-block">
														<el-input v-model="materialInfo.impression_label_1" maxlength="6" show-word-limit placeholder="2-6个字"></el-input>
													</div>
												</div>
												<div class="item">
													<div class="item-label">{{$t('seller.material.shopEffect')}}2</div>
													<div class="item-block">
														<el-input v-model="materialInfo.impression_label_2" maxlength="6" show-word-limit placeholder="2-6个字"></el-input>
													</div>
												</div>
												<div class="item">
													<div class="item-label">{{$t('seller.material.shopEffect')}}3</div>
													<div class="item-block">
														<el-input v-model="materialInfo.impression_label_3" maxlength="6" show-word-limit placeholder="2-6个字"></el-input>
													</div>
												</div>
												<div class="item">
													<div class="item-label">{{$t('seller.material.shopEffect')}}4</div>
													<div class="item-block">
														<el-select v-model="materialInfo.impression_label_4">
															<el-option v-for="(item,index) in shopTagOption" :key="index" :label="item" :value="item"></el-option>
														</el-select>
													</div>
												</div>
											</div>
											<div class="desc-action"><el-button @click="handleEffectSubmit" size="mini">{{$t('seller.actions.submit')}}</el-button></div>
										</div>
										<div class="table-error ml24">{{errorText.impression_label?errorText.impression_label:''}}</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="page-scene">
						<div class="scene-title">{{$t('seller.material.shopScene')}}</div>
						<div class="scene-main">
							<div class="item" v-for="(item,index) in shopSceneOption" :key="index">{{item}}</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<el-dialog
			:title="$t('seller.dialogTitle.image')"
			class="dialog-upload"
			width="960px"
			:visible.sync="dialogUploadVisible"
			:before-close="handleUploadClose">
			<upload-material ref="uploadImage" @get-uplod-action="handleUploadAction"></upload-material>
		</el-dialog>
		<el-dialog
			:title="$t('seller.dialogTitle.example')"
			:visible.sync="dialogExplainVisible"
			width="900px"
			class="dialog-explain"
			:before-close="handleExplainClose">
			<div class="dialog-explain-body" v-if="isExplainShow == 1">
				<div class="dialog-explain-title">{{$t('seller.tableHead.shopIntro')}}</div>
				<div class="dialog-explain-main">{{$t('seller.material.shopTableTips1')}}</div>
			</div>
			<div class="dialog-explain-body" v-if="isExplainShow == 2">
				<div class="dialog-explain-title">{{$t('seller.tableHead.shopWideGraph')}}</div>
				<div class="dialog-explain-main">{{$t('seller.material.shopTableTips2')}}</div>
			</div>
			<div class="dialog-explain-body" v-if="isExplainShow == 3">
				<div class="dialog-explain-title">{{$t('seller.tableHead.shopLongGraph')}}</div>
				<div class="dialog-explain-main">{{$t('seller.material.shopTableTips2')}}</div>
			</div>
			<div class="dialog-explain-body" v-if="isExplainShow == 4">
				<el-scrollbar>
					<div class="dialog-explain-title">{{$t('seller.material.shopEffect')}}1</div>
					<div class="dialog-explain-title">{{$t('seller.material.shopEffect')}}2</div>
					<div class="dialog-explain-title">{{$t('seller.material.shopEffect')}}3</div>
					<div class="dialog-explain-title">{{$t('seller.material.shopEffect')}}4</div>
					<div class="dialog-explain-main">
						<img src="@/assets/images/seller/ps-1548-4412.png" width="100%">
					</div>
				</el-scrollbar>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import uploadMaterial from '@/components/seller/uploadMaterial.vue';
	import { fetchMaterial, updateMaterial } from '@/api/seller/shop';
	import { normalOSSUpload } from '@/utils/upload';
	
	export default {
		name: 'shopMaterial',
		components: { uploadMaterial },
		data() {
			return {
				subactiveName: '1-2',
				materialInfo: {
					intro: '',
					wide_graph: '',
					long_graph: '',
					impression_label_1: '',
					impression_label_2: '',
					impression_label_3: '',
					impression_label_4: '',
				},
				shopTagOption: this.$t('seller.material.shopTagOption'),
				errorText: {
					intro: '',
					wide_graph: '',
					long_graph: '',
					impression_label:'',
				},
				dialogUploadVisible: false,
				uploadType: '',
				dialogExplainVisible: false,
				isExplainShow: 1,
				loadingUplaodW: false,
				loadingUplaodL: false,
				shopSceneOption: this.$t('seller.material.shopSceneOption')
			}
		},
		created() {
			this.getMaterialInfo();
		},
		methods:{
			getMaterialInfo(){
				fetchMaterial().then(response => {
					this.materialInfo = response.data
				})
			},
			handleDescSubmit(){
				let errorText = '';
				if(!this.materialInfo.intro){
					errorText = this.$t('seller.errorMsg.submitData');
				}else if(this.materialInfo.intro.length<16){
					errorText = this.$t('seller.errorMsg.shopIntroMin') + '16';
				}else if(this.materialInfo.intro.length>24){
					errorText = this.$t('seller.errorMsg.shopIntroMax') + '24';
				}
				if(errorText){
					this.errorText.intro = errorText;
					return false;
				}
				let param = {
					intro:this.materialInfo.intro
				}
				this.handleSave(param);
			},
			strlen(str){
				var len = 0;
				for(let i=0;i<str.length;i++){
					var c= str.charCodeAt(i);
					if((c>=0x001 && c<=0x007e) || (0xff60<=c && c<=0xff9f)){
						len++;
					}else {
						len+=2;
					}
				}
				return len;
			},
			httpRequestMaterialW(value){
				const { file } = value;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 < 500;
				if (!istype) {
					this.$message.error(this.$t('seller.upload.imageType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.imageSize500K'));
					return false;
				}
				let width = 900;
				let height = 506;
				let _url = window.URL || window.webkitURL;
				let img = new Image();
				img.src = _url.createObjectURL(file);
				img.onload = async ()=>{
					let valid = img.width == width && img.height==height;
					if(valid){
						this.loadingUplaodW = true;
						const { code, data } = await normalOSSUpload(file,'other');
						if(code==200){
							this.errorText.wide_graph = '';
							this.materialInfo.wide_graph = data.url;
							this.handleSave({wide_graph: data.url});
						}else{
							this.$message.error(this.$t('seller.errorMsg.upload'))
						}
						this.loadingUplaodW = false;
					}else{
						this.errorText.wide_graph = this.$t('seller.errorMsg.shopWideGraph');
					}
				}
			},
			httpRequestMaterialL(value){
				const { file } = value;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 < 500;
				if (!istype) {
					this.$message.error(this.$t('seller.upload.imageType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.imageSize500K'));
					return false;
				}
				let width = 900;
				let height = 1600;
				let _url = window.URL || window.webkitURL;
				let img = new Image();
				img.src = _url.createObjectURL(file);
				img.onload = async ()=>{
					let valid = img.width == width && img.height==height;
					if(valid){
						this.loadingUplaodL = true;
						const { code, data } = await normalOSSUpload(file,'other');
						if(code==200){
							this.errorText.long_graph = '';
							this.materialInfo.long_graph = data.url;
							this.handleSave({long_graph: data.url});
						}else{
							this.$message.error(this.$t('seller.errorMsg.upload'))
						}
					}else{
						this.errorText.long_graph = this.$t('seller.errorMsg.shopLongGraph');
					}
				}
			},
			handleUploadShow(type){
				this.uploadType = type;
				this.dialogUploadVisible = true;
			},
			handleUploadClose(){
				this.dialogUploadVisible = false;
				this.$refs.uploadImage.isUpload = true;
				this.$refs.uploadImage.isAddFolder = false;
			},
			handleUploadAction(data){
				let that = this;
				that.dialogUploadVisible = false;
				if(data.type==1){
					let img = new Image();
					img.src = data.img_url;
					if(that.uploadType=='long'){
						let width = 900;
						let height = 1600;
						img.onload = function(){
							let valid = img.width == width && img.height==height;
							if(valid){
								that.materialInfo.long_graph = data.img_url;
								that.handleSave({long_graph:data.img_url});
								that.errorText.long_graph = '';
							}else {
								that.errorText.long_graph = that.$t('seller.errorMsg.shopLongGraph');
							}
						}
					}else{
						let width = 900;
						let height = 506;
						img.onload = function(){
							let valid = img.width == width && img.height==height;
							if(valid){
								that.materialInfo.wide_graph = data.img_url;
								that.handleSave({wide_graph:data.img_url});
								that.errorText.wide_graph = '';
							}else {
								that.errorText.wide_graph = that.$t('seller.errorMsg.shopWideGraph');
							}
						}
					}
				}
			},
			handleEffectSubmit(){
				this.errorText.impression_label = '';
				if(!this.materialInfo.impression_label_1 && !this.materialInfo.impression_label_2 && !this.materialInfo.impression_label_3 && !this.materialInfo.impression_label_4){
					this.errorText.impression_label = this.$t('seller.errorMsg.submitData');
					return false;
				}
				let errorText = '';
				if(!this.materialInfo.impression_label_1){
					errorText += this.$t('seller.errorMsg.shopEffect')+'1:'+this.$t('seller.errorMsg.required');
				}
				if(!this.materialInfo.impression_label_4){
					errorText += this.$t('seller.errorMsg.shopEffect')+'4:'+this.$t('seller.errorMsg.required');
				}
				let labelLeng1 = this.strlen(this.materialInfo.impression_label_1);
				let labelLeng2 = this.strlen(this.materialInfo.impression_label_2);
				let labelLeng3 = this.strlen(this.materialInfo.impression_label_3);
				if(this.materialInfo.impression_label_1 && this.materialInfo.impression_label_1.length<2){
					errorText += this.$t('seller.errorMsg.shopEffect') + '1:' + this.$t('seller.errorMsg.lengthMin') + '2';
				}else if(this.materialInfo.impression_label_1 && this.materialInfo.impression_label_1.length>6){
					errorText += this.$t('seller.errorMsg.shopEffect') + '1' + this.$t('seller.errorMsg.lengthMax') + '6';
				}
				if(this.materialInfo.impression_label_2 && this.materialInfo.impression_label_2.length<2){
					errorText += this.$t('seller.errorMsg.shopEffect') + '2' + this.$t('seller.errorMsg.lengthMin') + '2';
				}else if(this.materialInfo.impression_label_2 && this.materialInfo.impression_label_2.length>6){
					errorText += this.$t('seller.errorMsg.shopEffect') + '2' + this.$t('seller.errorMsg.lengthMax') + '6';
				}
				if(this.materialInfo.impression_label_3 && this.materialInfo.impression_label_3.length<2){
					errorText += this.$t('seller.errorMsg.shopEffect') + '3' + this.$t('seller.errorMsg.lengthMin') + '2';
				}else if(this.materialInfo.impression_label_3 && this.materialInfo.impression_label_3.length>6){
					errorText += this.$t('seller.errorMsg.shopEffect') + '3' + this.$t('seller.errorMsg.lengthMax') + '6';
				}
				if(errorText){
					this.errorText.impression_label = errorText;
					return false;
				}
				let param = {
					impression_label_1: this.materialInfo.impression_label_1,
					impression_label_2: this.materialInfo.impression_label_2,
					impression_label_3: this.materialInfo.impression_label_3,
					impression_label_4: this.materialInfo.impression_label_4,
				}
				this.handleSave(param);
			},
			handleSave(param){
				updateMaterial(param).then(() => {
					this.errorText.intro = '';
					this.$message.success(this.$t('seller.successMsg.save'))
				})
			},
			handleExplainShow(type){
				this.isExplainShow = type;
				this.dialogExplainVisible = true;
			},
			handleExplainClose(){
				this.dialogExplainVisible = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
.material-upload {
	::v-deep .el-tabs {
		padding: 5px 0 0;
		.el-tabs__header {
			margin: 0!important;
			.el-tabs__nav-wrap {
				padding: 0 14px;
			}
		}
		.el-tabs__content {
			background-color: #f2f5fa;
		}
	}
	.material-wrap {
		padding: 0 20px;
		margin-top: 20px;
		.page-warn {
			padding: 10px;
			margin-bottom: 10px;
			line-height: 30px;
			background-color: #FFFFFF;
			.el-icon-warning-outline {
				font-size: 24px;
				color: $--seller-primary;
				margin-right: 10px;
				display: inline-block;
				vertical-align: middle;
			}
			span {
				font-size: 14px;
				display: inline-block;
				vertical-align: middle;
			}
		}
		.page-steps {
			display: flex;
			padding: 8px 16px;
			line-height: 32px;
			margin-bottom: 8px;
			background-color: #FFFFFF;
			.item-icon {
				width: 32px;
				height: 32px;
				vertical-align: top;
			}
			.item-text {
				vertical-align: top;
				font-size: 14px;
			}
			.item-line {
				height: 3px;
				margin: 14px 8px 15px;
				vertical-align: top;
			}
		}
		.page-table {
			padding-bottom: 20px;
			margin-bottom: 38px;
			background-color: #FFFFFF;
			table {
				width: 100%;
				th {
					padding: 18px;
					font-size: 16px;
					color: #333333;
					text-align: center;
					border: 1px solid #d7dbe0;
					.el-icon-warning-outline {
						font-size: 20px;
						color: $--seller-primary;
						cursor: pointer;
						margin-left: 5px;
						vertical-align: middle;
					}
				}
				td {
					padding: 12px;
					border: 1px solid #d7dbe0;
				}
				tr:first-child {
					td {
						border-top-width: 0;
					}
				}
			}
			::v-deep .el-select {
				width: 100%;
			}
			::v-deep .el-input {
				.el-input__inner {
					padding-right: 48px;
				}
				.el-input-group__append {
					background-color: transparent;
					.el-input__inner {
						border-right: 0;
					}
				}
			}
			.fake-upload {
				width: 90px;
				height: 90px;
				display: inline-block;
				text-align: center;
				vertical-align: middle;
				position: relative;
				cursor: pointer;
				overflow: hidden;
				border: 1px dashed #D7DBE0;
				.fake-upload-main {
					width: 100%;
					height: 100%;
					position: relative;
					.el-icon-plus {
						font-size: 36px;
						height: 46px;
						line-height: 46px;
					}
				}
				.fake-upload-text {
					font-size: 14px;
					margin-bottom: 8px;
					line-height: normal;
				}
				.fake-upload-size {
					font-size: 12px;
				}
				.fake-upload-pic {
					top: 50%;
					max-width: 90px;
					max-height: 90px;
					transform: translateY(-50%);
					position: relative;
				}
				.fake-upload-bottom {
					bottom: -40px;
					width: 90px;
					display: flex;
					font-size: 11px;
					position: absolute;
					transition: all .3s ease-in-out;
					::v-deep .el-upload {
						&:active,&:focus {
							color: #FFFFFF;
						}
					}
				}
				.fake-upload-btn {
					flex: 1;
					color: #FFFFFF;
					height: 24px;
					line-height: 24px;
					font-size: 16px;
					text-align: center;
					&.btn-primary {
						background-color: $--seller-button-primary;
						&:hover {
							background-color: $--seller-button-primary-hover;
						}
					}
					&.btn-blue {
						background-color: rgb(85, 189, 249);
					}
				}
				&:hover {
					border-color: $--seller-primary;
					.fake-upload-main {
						color: $--seller-primary;
					}
					.fake-upload-bottom {
						bottom: 0;
					}
				}
			}
			.desc-action {
				text-align: center;
				.el-button {
					color: $--seller-button-primary;
					font-size: 12px;
					padding: 5px 6px;
					border: 1px solid $--seller-button-primary;
				}
				&.mt10 {
					margin-top: 10px;
				}
			}
			.table-error {
				color: #dd2727;
				font-size: 12px;
				min-height: 16px;
				line-height: 16px;
				text-align: justify;
				margin-top: 4px;
				&.ml24 {
					margin-left: 24px;
				}
			}
			.table-effect {
				display: flex;
				padding: 10px;
				align-items: center;
				border: 1px dashed #eeeeee;
				.effect-list {
					flex: 1;
					border-right: 1px solid #EEEEEE;
					.item {
						display: inline-block;
						margin-right: 10px;
						width: calc(50% - 10px);
						max-width: 220px;
						box-sizing: border-box;
						.item-label {
							font-size: 12px;
							line-height: 15px;
							margin-bottom: 4px;
							text-align: center;
						}
						.item-block {
							margin-bottom: 12px;
						}
					}
				}
				.desc-action {
					width: 60px;
					margin-right: 4px;
					margin-left: 10px;
					display: inline-block;
				}
			}
		}
		.page-scene {
			padding: 20px;
			margin-bottom: 32px;
			background-color: #FFFFFF;
			.scene-title {
				margin-bottom: 18px;
				font-size: 16px;
				line-height: 20px;
			}
			.scene-main {
				display: grid;
				justify-content: space-between;
				grid-template-columns: repeat(auto-fill, 103px);
				.item {
					width: 103px;
					height: 39px;
					line-height: 39px;
					font-size: 14px;
					color: #333333;
					text-align: center;
					box-sizing: border-box;
					border: 1px solid #dcdcdc;
					margin-bottom: 12px;
				}
			}
		}
	}
	.dialog-explain {
		::v-deep .el-dialog {
			.el-dialog__body {
				height: auto;
				padding: 24px;
				font-size: 14px;
				color: #666666;
			}
		}
		::v-deep .el-scrollbar {
			height: 600px;
		}
		.dialog-explain-title {
			line-height: normal;
			margin-bottom: 8px;
			color: #999999;
		}
		.dialog-explain-main {
			line-height: normal;
		}
	}
}
</style>
