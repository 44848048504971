<template>
	<div class="uu-upload">
		<div class="uu-upload-title">{{$t('seller.material.uploadTitle')}}</div>
		<div class="uu-upload-main uu-flex" v-if="isUpload">
			<div class="uu-upload-menu">
				<div class="menu-search">
					<el-input v-model="currentNodename" size="mini" readonly>
						<i slot="prefix" class="el-input__icon el-icon-search"></i>
					</el-input>
				</div>
				<div class="menu-list">
					<el-scrollbar>
						<el-tree
							:data="folderList"
							ref="folderList"
							node-key="material_id"
							:default-expanded-keys="defaultExpandedKeys"
							:default-checked-keys="defaultCheckedKeys"
							:current-node-key="currentNodekey"
							:props="{label:'name'}"
							@current-change="handleFolderCurrentChange">
						</el-tree>
					</el-scrollbar>
				</div>
			</div>
			<div class="uu-upload-box">
				<div class="uu-upload-head">
					<ul>
						<li>
							<el-input v-model="keyword" size="mini" clearable :placeholder="$t('seller.placeholder.searchImg')" @change="handleSeachKeyword">
								<i slot="prefix" class="el-input__icon el-icon-search"></i>
							</el-input>
						</li>
						<li class="right"><el-button size="mini" @click="isUpload = false">{{$t('seller.actions.uploadImg')}}</el-button></li>
					</ul>
				</div>
				<el-scrollbar>
					<template v-if="thumbList && thumbList.length>0">
						<div class="uu-upload-list" v-if="uploadType">
							<el-radio-group v-model="checkThumb">
								<ul>
									<li v-for="item in thumbList" :key="item.material_id">
										<el-radio :label="item.url">
											<div class="li-pic">
												<img :src="item.url" alt="">
												<div class="li-pic-bottom">{{item.wide}}x{{item.high}}</div>
											</div>
											<div class="li-name">{{item.name}}</div>
										</el-radio>
									</li>
								</ul>
							</el-radio-group>
						</div>
						<div class="uu-upload-list" v-else>
							<ul>
								<li v-for="item in thumbList" :key="item.material_id" @click="handleUploadSelect(item.url)">
									<div class="li-pic">
										<img :src="item.url" alt="">
										<div class="li-pic-bottom">{{item.wide}}x{{item.high}}</div>
									</div>
									<div class="li-name">{{item.name}}</div>
								</li>
							</ul>
						</div>
						<div style="text-align: center;padding-top: 5px;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="pagecurr"
								:page-size="pagesize"
								layout="total, prev, pager, next"
								:prev-text="$t('seller.pagination.prevText')"
								:next-text="$t('seller.pagination.nextText')"
								:total="listTotal">
							</el-pagination>
						</div>
					</template>
					<template v-else>
							<el-empty :description="$t('seller.empty.material')"></el-empty>
					</template>
				</el-scrollbar>
			</div>
		</div>
		<div class="uu-upload-main" v-else>
			<div class="uu-upload-head">
				<ul>
					<li>
						<span class="li-label mr8">{{$t('seller.material.uploadTo')+':'}}</span>
						<div class="li-block">
							<template v-if="isAddFolder">
								<el-input v-model="newFolderName" :placeholder="$t('seller.placeholder.newFolder')" size="mini"></el-input>
							</template>
							<template v-else>
								<el-popover
									v-model="popoverDialogImages"
									placement="bottom-start"
									width="180"
									trigger="click">
									<template slot="reference">
										<el-input v-model="uploadForm.pname" size="mini" readonly></el-input>
									</template>
									<el-tree
										:data="folderList"
										class="uu-folder-tree"
										node-key="material_id"
										:default-expanded-keys="defaultExpandedKeys"
										:default-checked-keys="defaultCheckedKeys"
										:current-node-key="currentNodekey"
										:props="{label:'name'}"
										@current-change="handleImagesPopoverCurrentChange">
									</el-tree>
								</el-popover>
							</template>
						</div>
					</li>
					<li>
						<template v-if="isAddFolder">
							<a @click="handleFolderAdd" class="mr8">{{$t('seller.actions.confirm')}}</a>
							<a @click="handleFolderClose">{{$t('seller.actions.cancel')}}</a>
						</template>
						<template v-else>
							<a @click="isAddFolder = true">{{$t('seller.actions.newFolder')}}</a>
						</template>
					</li>
				</ul>
			</div>
			<el-upload
				class="uu-upload-how"
				drag
				action="#"
				multiple
				v-loading="loadingUplaod"
				:show-file-list="false"
				:http-request="httpRequestMaterialUpload">
				<img class="el-upload__pic" src="@/assets/images/seller/upload-200-119.png" alt="">
				<div class="el-upload__text">
					<p>{{$t('seller.material.uploadText6')}}<em>{{$t('seller.material.uploadText2')}}</em></p>
					<p>{{$t('seller.material.uploadText7')}}</p>
				</div>
			</el-upload>
		</div>
		<div class="uu-upload-foot">
			<a href="/home/decorate/material" target="_blank">{{$t('seller.actions.picManage')}}</a>
			<div class="right">
				<template v-if="isUpload">
					<template v-if="uploadType">
						<el-button size="mini" @click="handleUploadClose">{{$t('seller.actions.cancel')}}</el-button>
						<el-button type="primary" size="mini" @click="handleUploadSubmit">{{$t('seller.actions.confirm2')}}</el-button>
					</template>
				</template>
				<el-button v-else @click="isUpload = true" size="mini">{{$t('seller.actions.cancel')}}</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { fetchMetarial, listFolder, createFolder  } from '@/api/seller/decorate'
	import { materialOSSUpload  } from '@/utils/upload'
	export default {
		name: 'ellerUploadMaterial',
		data() {
			return {
				isUpload: true,
				folderList: [],
				defaultExpandedKeys: [],
				defaultCheckedKeys: [],
				currentNodekey: 1,
				currentNodename: '我的图片',
				thumbList: [],
				listTotal: 0,
				pagecurr: 1,
				pagesize: 12,
				keyword: '',
				popoverDialogImages: false,
				loadingUplaod: false,
				isAddFolder: false,
				uploadForm: {
					pid: '',
					pname: '',
				},
				newFolderName: '',
				checkThumb: '',
			}
		},
		props: {
			uploadType: {
				default: true,
			},
			uploadAction: {
				default: '',
			}
		},
		created() {
			this.getFolderList();
		},
		methods: {
			getFolderList(){
				listFolder({uuid: this.$store.getters.uuid}).then(response => {
					const { data } = response;
					this.folderList = data.list
					this.defaultExpandedKeys.push(data.list[0].material_id);
					this.defaultCheckedKeys.push(data.list[0].material_id);
					this.currentNodekey = this.currentNodekey?this.currentNodekey:data.list[0].material_id;
					this.currentNodename = this.currentNodename?this.currentNodename:data.list[0].name;
					this.getThumbList();
					if(this.isUpload){
						this.$nextTick(()=>{
							this.$refs.folderList.setCurrentKey(this.currentNodekey);
						})
					}
				})
			},
			getThumbList(){
				this.checkFolderName = this.currentNodename;
				this.uploadForm.pname = this.currentNodename;
				this.uploadForm.pid = this.currentNodekey;
				let params = {
					page_index: (this.pagecurr-1)*this.pagesize,
					page_size: this.pagesize,
					parent_id: this.currentNodekey,
					type: 0,
					keyword: this.keyword
				}
				fetchMetarial(params).then(response => {
					this.thumbList = response.data.list;
					this.listTotal = parseInt(response.data.total);
				})
			},
			handleSizeChange(val){
				this.pagesize = val;
			},
			handleCurrentChange(val){
				this.pagecurr = val;
				this.getThumbList();
			},
			handleSeachKeyword(){
				this.pagecurr = 1;
				this.getThumbList();
			},
			handleFolderCurrentChange(data,node){
				this.currentNodename = data.name;
				this.currentNodekey = data.material_id;
				this.getThumbList();
			},
			handleImagesPopoverCurrentChange(data,node){
				this.uploadForm.pname = data.name;
				this.uploadForm.pid = data.material_id;
				this.popoverDialogImages = false;
			},
			handleFolderClose(){
				this.isAddFolder = false;
				this.newFolderName = '';
			},
			handleFolderAdd(){
				if(!this.newFolderName){
					this.$message.error(this.$t('seller.errorMsg.newFolderName'));
					return false
				}
				
				let params = {
					name: this.newFolderName,
					parent_id: this.uploadForm.pid
				}
				createFolder(params).then(response => {
					this.newFolderName = '';
					this.getFolderList();
					this.$message.success(this.$t('seller.successMsg.create'));
					this.isAddFolder = false;
				})
			},
			async httpRequestMaterialUpload(value){
				const { file } = value;
				const isType = ['image/jpeg','image/png','image/gif'].includes(file.type);
				const isSize = file.size / 1024 / 1024 < 3;
				if (!isType) {
					this.$message.error(this.$t('seller.upload.imageType2'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.imageSize3M'));
					return false;
				}
				
				this.loadingUplaod = true;
				const { code, data } = await materialOSSUpload(this.uploadForm.pid,0,file,'material')
				if(code===200) {
					this.isUpload = true;
					this.getThumbList();
					if(this.uploadAction == 'goods'){
						this.$emit('get-uplod-action',data.url);
					}
				}
				this.loadingUplaod = false;
			},
			handleUploadClose(){
				this.$emit('get-uplod-action',{type:0});
				this.checkThumb = '';
			},
			handleUploadSelect(url){
				this.$emit('get-uplod-action',url);
			},
			handleUploadSubmit(){
				this.$emit('get-uplod-action',{type:1,img_url:this.checkThumb});
				this.checkThumb = '';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-upload {
		padding: 0 20px;
		.uu-upload-title {
			color: #8b572a;
			height: 28px;
			font-size: 12px;
			line-height: 28px;
			border-radius: 2px;
			text-align: center;
			margin-bottom: 5px;
			background-color: #fdedd3;
		}
		.uu-upload-main {
			height: 402px;
			.uu-upload-menu {
				height: 100%;
				width: 160px;
				margin-right: 20px;
				border-radius: 2px;
				border: 1px solid #CCCCCC;
				box-sizing: border-box;
				.menu-search {
					border-bottom: 1px solid #CCCCCC;
					::v-deep .el-input__inner {
						border: none;
					}
				}
				.menu-list {
					height: calc(100% - 29px);
					::v-deep .el-tree {
						.el-tree-node.is-current>div.el-tree-node__content {
							.el-tree-node__label {
								color: $--seller-primary;
							}
						}
					}
				}
			}
			.uu-upload-box {
				flex: 1;
				.el-scrollbar {
					height: calc(100% - 76px);
				}
			}
			.uu-upload-head {
				overflow: hidden;
				li {
					line-height: 28px;
					display: inline-block;
					margin-right: 10px;
					margin-bottom: 10px;
				}
				li:last-child {
					margin-right: 0;
				}
				.uu-icon {
					width: 20px;
					height: 20px;
					display: inline-block;
					vertical-align: middle;
					background-size: auto 20px;
					background-repeat: no-repeat;
					background-image: url(~@/assets/images/seller/ic-material-head.png);
				}
				.uu-icon-vertical {
					background-position: 0 0;
				}
				.uu-icon-vertical.active {
					background-position: -20px 0;
				}
				.uu-icon-level {
					background-position: -40px 0;
				}
				.uu-icon-level.active {
					background-position: -60px 0;
				}
				::v-deep .el-input--prefix {
					.el-input-group__prepend {
						background-color: transparent;
						.el-select {
							width: 68px;
							.el-input__inner {
								padding-left: 10px;
								padding-right: 20px;
							}
							.el-input__suffix {
								right: 0;
							}
						}
					}
				}
				::v-deep .el-select {
					.el-input--mini {
						.el-input__inner {
							padding-left: 10px;
						}
					}
				}
				.right {
					float: right;
				}
				.li-block {
					display: inline-block;
				}
				.mr8 {
					margin-right: 8px;
				}
				a {
					color: $--seller-primary;
					cursor: pointer;
				}
			}
			
			.uu-upload-list {
				li {
					width: 100px;
					display: inline-block;
					vertical-align: top;
					position: relative;
					margin: 0 5px 10px;
					cursor: pointer;
					::v-deep .el-radio {
						display: block;
						.el-radio__input {
							top: 4px;
							left: 4px;
							z-index: 9;
							position: absolute;
						}
						.el-radio__label {
							padding: 0;
						}
					}
					.li-pic {
						width: 100px;
						height: 100px;
						overflow: hidden;
						position: relative;
						box-shadow: 0 0 0 1px #EEEEEE inset;
						img {
							width: 100px;
							height: 100px;
							object-fit: contain;
						}
						.li-pic-bottom {
							left: 0;
							bottom: 0;
							width: 100%;
							height: 20px;
							line-height: 20px;
							color: #FFFFFF;
							font-size: 12px;
							text-align: center;
							background-color: rgba(0,0,0,.6);
							position: absolute;
							transition: bottom .3s ease-out;
						}
					}
					.li-name {
						font-size: 12px;
						height: 32px;
						line-height: 32px;
						color: #666666;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				li:hover {
					.li-pic-bottom {
						bottom: -40px;
					}
				}
			}
			.uu-upload-how {
				height: calc(100% - 38px);
				::v-deep .el-upload {
					width: 100%;
					height: 100%;
					.el-upload-dragger {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						background-color: transparent;
						.el-upload__text {
							margin-top: 20px;
							p {
								height: 16px;
								font-size: 12px;
								line-height: 16px;
							}
						}
					}
				}
			}
		}
		.uu-upload-main.uu-flex {
			display: flex;
		}
		.uu-upload-foot {
			height: 28px;
			line-height: 28px;
			padding: 20px 0;
			a {
				color: $--seller-primary;
				text-decoration: underline;
				font-size: 12px;
			}
			.right {
				float: right;
			}
		}
	}
	.uu-folder-tree {
		::v-deep .el-tree-node.is-current>div.el-tree-node__content {
			.el-tree-node__label {
				color: $--seller-primary;
			}
		}
	}
</style>
