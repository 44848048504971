<template>
	<div class="uu-material">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane :label="$t('seller.material.pagePane1')" name="1">
				<el-scrollbar class="uu-box">
					<shop-material></shop-material>
				</el-scrollbar>
			</el-tab-pane>
			<el-tab-pane :label="$t('seller.material.pagePane2')" name="4">
				<decorate-material></decorate-material>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import shopMaterial from '@/views/seller/shop/shopMaterial.vue'
	import decorateMaterial from '@/views/seller/decorate/material.vue'
	export default {
		components: { shopMaterial, decorateMaterial },
		data() {
			return {
				activeName: '1'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-material {
		flex: 1;
		width: 0;
		::v-deep .el-tabs {
			.el-tabs__header {
				margin: 10px 10px 15px;
			}
		}
		.material-upload {
			padding: 0 10px;
		}
		.material-container {
			padding: 0 12px 30px;
		}
	}
	.uu-box {
		height: calc(100vh - 141px);
	}
	.material-container {
		height: calc(100vh - 141px);
	}
</style>
<style>
/* 	.uu-material .el-tabs {
		height: 100%;
		padding: 10px;
		box-sizing: border-box;
	}
	.uu-material .el-tabs__content {
		height: calc(100% - 56px);
	}
	.uu-material .el-tab-pane {
		height: 100%;
	}
	.uu-material .material-container {
		padding: 0 12px 30px;
	} */
</style>
